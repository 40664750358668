import React, { useEffect, useState } from 'react';
// import { Button } from 'react-admin';
import {
  Aftercare,
  AftercareLocation,
  AftercareLog,
  AftercareStatus,
  MemorialOption,
  Pet,
  Runnable,
  Event,
} from '../shared/types';
import { Box, makeStyles, Toolbar, Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { get } from '../../utils/Api';
import extractUUID from '../../utils/ExtractUUID';
import Typography from '@material-ui/core/Typography';
import { Notification } from '../shared/Notification';
import { updateEvent } from '../../utils/AppointmentUtils';
import { getAppointmentTypeById } from '../../utils/AppointmentTypes';
import { sortByDeleted } from '../../utils/DataConverters';
import moment from 'moment-timezone';
interface Props {
  record?: Pet;
  reload?: boolean;
  editing: boolean;
  disableEditing: Runnable;
}

const styles = makeStyles({
  emptyContainer: {
    margin: '30px',
    minHeight: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyLabel: {
    textAlign: 'center',
    color: '#626262',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '18px',
    fontWeight: 400,
    maxWidth: '400px',
  },
  petsContainer: {
    margin: '30px',
  },
  header: {
    display: 'flex',
    borderBottom: 'solid 1px',
    justifyContent: 'space-between',
    paddingBottom: '16px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '90px',
    paddingTop: '16px',
  },
  headLeft: {
    left: 0,
    width: '100%',
  },
  headRight: {
    right: 0,
    width: '100%',
  },
  formCtrl: {
    display: 'flex',
    flexDirection: 'row',
  },
  notesBox: {
    paddingTop: '11px',
    height: '230px',
    overflow: 'auto',
    borderBottom: 'solid 1px',
  },
  note: {
    backgroundColor: '#F0F0F0',
    marginBottom: '10px',
    marginRight: '30px',
    padding: '10px',
    borderRadius: '10px',
    color: '#626262',
    fontFamily: 'Mulish',
    fontWeight: 300,
  },
  meta: {
    fontSize: '13px',
    fontWeight: 700,
  },
  comment: {
    fontSize: '13px',
    fontWeight: 400,
  },
  addOnLabel: {
    fontSize: '0.625rem',
    marginRight: '50px',
    marginTop: '5px',
  },
  tinyDividedField: {
    maxWidth: '29px',
    width: '100%',
    marginRight: '6px',
  },
  numberInput: {
    '& input[type=number]': {
      paddingLeft: '2px !important',
      paddingRight: '2px !important',
      paddingTop: '9px !important',
      paddingBottom: '9px !important',
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  toolbarBox: {
    borderTop: '1px solid',
    paddingTop: '10px',
    marginBottom: '20px',
  },
  saveButton: {
    margin: '0px 10px',
    padding: '0.75em 1.5em',
    fontWeight: 500,
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#373F41',
    '&:hover': {
      backgroundColor: 'rgba(55, 63, 65, 0.6)',
    },
  },
  cancelButton: {
    margin: '0px 10px',
    padding: '0.75em 2em 0.75em 1.5em',
    fontWeight: 500,
    fontSize: '14px',
  },
});

export const AftercareLogBlock: React.FC<Props> = ({
  record,
  editing,
  disableEditing,
}) => {
  const classes = styles();
  const [aftercareStatuses, setAftercareStatuses] = useState<AftercareStatus[]>(
    [],
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [reload, setReload] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [aftercares, setAftercares] = useState<Aftercare[]>([]);
  const [clayPaws, setClayPaws] = useState<number>(0.0);
  const [inkPaws, setInkPaws] = useState<number>(0.0);
  const [nosePrints, setNosePrints] = useState<number>(0.0);
  const [aftercare, setAftercare] = useState<Aftercare | null>(null);
  const [
    aftercareStatus,
    setAftercareStatus,
  ] = useState<AftercareStatus | null>(null);
  const [aftercareLocations, setAftercareLocations] = useState<
    AftercareLocation[]
  >([]);
  const [
    aftercareLocation,
    setAftercareLocation,
  ] = useState<AftercareLocation | null>(null);
  const [aftercareLogs, setAftercareLogs] = useState<AftercareLog[]>([]);
  const [memorialOptions, setMemorialOptions] = useState<MemorialOption[]>([]);
  const [lastEvent, setLastEvent] = useState<Event | null>(null);

  useEffect(() => {
    const getAftercareStatuses = () => {
      get({
        endpoint: `aftercare_statuses?deleted=false&order%5BsortOrder%5D=asc`,
      }).then(async response => {
        const aftercareStatusesJson = await response.json();
        setAftercareStatuses(aftercareStatusesJson['hydra:member']);
      });
    };

    getAftercareStatuses();
  }, []);

  useEffect(() => {
    const getFilteredAftercareLocations = () => {
      get({
        endpoint: `aftercare_locations?deleted=false`,
      }).then(async response => {
        const aftercareLocationsJson = await response.json();
        setAftercareLocations(aftercareLocationsJson['hydra:member']);
      });
    };

    getFilteredAftercareLocations();
  }, []);

  useEffect(() => {
    const getFilteredAftercares = () => {
      get({
        endpoint: `aftercares?deleted=false`,
      }).then(async response => {
        const aftercaresJson = await response.json();
        setAftercares(aftercaresJson['hydra:member']);
      });
    };

    getFilteredAftercares();
  }, []);

  useEffect(() => {
    const getMemorialOptions = () => {
      get({
        endpoint: `memorial_options?deleted=false`,
      }).then(async response => {
        const memOptionsJson = await response.json();
        setMemorialOptions(memOptionsJson['hydra:member']);
      });
    };

    getMemorialOptions();
  }, []);

  useEffect(() => {
    const getFilteredAftercareLogs = () => {
      get({
        endpoint: `aftercare_logs?pet.id=${extractUUID(
          record?.id ?? '',
        )}&order%5BcreatedAt%5D=desc`,
      }).then(async response => {
        const aftercareLogsJson = await response.json();
        const logs = aftercareLogsJson['hydra:member'];
        setAftercareLogs(logs);
      });
    };

    getFilteredAftercareLogs();
  }, [aftercareStatuses, record, reload]);

  useEffect(() => {
    const getLatestEvent = () => {
      get({
        endpoint: `aftercare_appointment_events?pet.id=${extractUUID(
          record?.id ?? '',
        )}&order%5BstartTime%5D=desc&page=1&itemsPerPage=1&deleted=false`,
      }).then(async response => {
        const eventsJson = await response.json();
        const events = eventsJson['hydra:member'];
        setLastEvent(events[0]);
        setAftercareStatus(events[0]?.aftercareStatus);
        setAftercareLocation(events[0]?.aftercareLocation);
        setAftercare(events[0]?.aftercare);
        if (events[0] === undefined || events[0] === null) {
          disableEditing();
        }
      });
    };

    getLatestEvent();
  }, [aftercareStatuses, disableEditing, record]);

  const cancel = () => {
    if (record) {
      const cp = record.memorialOptions.find(
        _mem => _mem.memorialOption?.code === 'CP',
      );
      const ip = record.memorialOptions.find(
        _mem => _mem.memorialOption?.code === 'IP',
      );
      const np = record.memorialOptions.find(
        _mem => _mem.memorialOption?.code === 'NP',
      );
      if (cp) {
        setClayPaws(cp.amount);
      } else {
        setClayPaws(0);
      }
      if (ip) {
        setInkPaws(ip.amount);
      } else {
        setInkPaws(0);
      }
      if (np) {
        setNosePrints(np.amount);
      } else {
        setNosePrints(0);
      }
    }
    disableEditing();
    setReload(!reload);
  };

  const update = async () => {
    const updatedPet: Pet = JSON.parse(JSON.stringify(record));
    const memOpts = { cp: false, np: false, ip: false };
    record?.memorialOptions.forEach((_memOpt, index) => {
      if (_memOpt.id !== '') {
        switch (_memOpt.memorialOption?.code) {
          case 'CP':
            memOpts.cp = true;
            break;
          case 'IP':
            memOpts.ip = true;
            break;
          case 'NP':
            memOpts.np = true;
            break;
        }
      }
      const total =
        _memOpt.memorialOption?.code === 'CP'
          ? clayPaws
          : _memOpt.memorialOption?.code === 'NP'
          ? nosePrints
          : inkPaws;
      if (_memOpt.total !== total)
        updatedPet.memorialOptions[index].amount = total;
    });
    if (!memOpts.cp) {
      updatedPet.memorialOptions.push({
        id: '',
        amount: clayPaws,
        memorialOption: memorialOptions.find(_mo => _mo.code === 'CP'),
      });
    }
    if (!memOpts.ip) {
      updatedPet.memorialOptions.push({
        id: '',
        amount: inkPaws,
        memorialOption: memorialOptions.find(_mo => _mo.code === 'IP'),
      });
    }
    if (!memOpts.np) {
      updatedPet.memorialOptions.push({
        id: '',
        amount: nosePrints,
        memorialOption: memorialOptions.find(_mo => _mo.code === 'NP'),
      });
    }
    updatedPet.id = extractUUID(updatedPet.id);
    // const resPet = await updatePet(updatedPet);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lastEvent.pet = updatedPet;
    const updatedEvent: Event = JSON.parse(JSON.stringify(lastEvent));
    updatedEvent.aftercare = aftercare ?? undefined;
    updatedEvent.aftercareLocation = aftercareLocation ?? undefined;
    updatedEvent.aftercareStatus = aftercareStatus ?? undefined;
    updatedEvent.mailing = false; // Disable outgoing emails from the Aftercare Status section changes
    updatedEvent.petRecordsPage = true;

    updatedEvent['@type'] = getAppointmentTypeById(updatedEvent['@type']);
    const resEvent = await updateEvent(updatedEvent);
    if (resEvent.ok) {
      setSuccess(true);
      setSnackbarMessage('Updated successfully.');
      disableEditing();
    } else {
      setSuccess(false);
      setSnackbarMessage('There was an error while updating.');
    }
    setOpenSnackbar(true);
    setReload(!reload);
  };

  useEffect(() => {
    if (record) {
      const cp = record.memorialOptions.find(
        _mem => _mem.memorialOption?.code === 'CP',
      );
      const ip = record.memorialOptions.find(
        _mem => _mem.memorialOption?.code === 'IP',
      );
      const np = record.memorialOptions.find(
        _mem => _mem.memorialOption?.code === 'NP',
      );
      if (cp) {
        setClayPaws(cp.amount);
      }
      if (ip) {
        setInkPaws(ip.amount);
      }
      if (np) {
        setNosePrints(np.amount);
      }
    }
  }, [record]);

  const sortedAftercares = sortByDeleted(aftercares);

  return (
    <div className={classes.petsContainer}>
      <div className={classes.header}>
        <div className={classes.headLeft}>
          <FormControl
            variant="outlined"
            style={{ width: '100%' }}
            className={classes.formCtrl}
          >
            <InputLabel>Current Status:</InputLabel>
            <Autocomplete
              disabled={!editing}
              style={{ width: '60%' }}
              id="combo-box-demo"
              options={aftercareStatuses}
              renderInput={params => <TextField {...params} />}
              getOptionLabel={option => option.description.toString()}
              onChange={(evt, value) => setAftercareStatus(value)}
              value={aftercareStatus}
            />
          </FormControl>
        </div>
        <div className={classes.headRight}>
          <FormControl
            variant="outlined"
            style={{ width: '100%' }}
            className={classes.formCtrl}
          >
            <InputLabel>Aftercare Location:</InputLabel>
            <Autocomplete
              disabled={!editing}
              style={{ width: '60%' }}
              id="combo-box-demo"
              options={aftercareLocations}
              renderInput={params => <TextField {...params} />}
              getOptionLabel={option => option.name.toString()}
              onChange={(evt, value) => setAftercareLocation(value)}
              value={aftercareLocation}
            />
          </FormControl>
        </div>
      </div>
      <div>
        <Box className={classes.notesBox}>
          {(lastEvent === null || lastEvent === undefined) && (
            <div className={classes.emptyContainer}>
              <label className={classes.emptyLabel}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Pet does not have appointments.
              </label>
            </div>
          )}
          {aftercareLogs
            .filter(_log => _log.log !== '')
            .sort((a, b) => {
              const aDate = new Date(a.createdAt);
              const bDate = new Date(b.createdAt);
              return aDate.getTime() - bDate.getTime();
            })
            .map(log => {
              const noteDate = new Date(log.createdAt).toLocaleString();
              return (
                <Box key={`note-${log.id}`} className={classes.note}>
                  <label className={classes.meta}>{`${moment(noteDate).format(
                    'MM/DD/YYYY, h:mm A',
                  )}, ${log.user?.name ?? 'Admin'}: `}</label>
                  <label className={classes.comment}>{log.log}</label>
                </Box>
              );
            })}
        </Box>
      </div>
      <div className={classes.footer}>
        <div className={classes.headLeft}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel>Aftercare</InputLabel>
            <Autocomplete
              disabled={!editing}
              style={{ width: '60%', marginTop: '24px' }}
              id="combo-box-demo"
              options={sortedAftercares}
              renderInput={params => <TextField {...params} />}
              getOptionLabel={option => option.description.toString()}
              getOptionDisabled={option => option.deleted}
              onChange={(evt, value) => setAftercare(value)}
              value={aftercare}
            />
          </FormControl>
        </div>
        <div className={classes.headRight}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel>Cremation Options</InputLabel>
            <div style={{ display: 'flex', marginTop: '24px' }}>
              <FormControl
                variant="outlined"
                className={classes.tinyDividedField}
              >
                <TextField
                  disabled={!editing}
                  type="number"
                  className={classes.numberInput}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={clayPaws}
                  onChange={e => {
                    setClayPaws(Number(e.target.value));
                  }}
                  variant="outlined"
                />
              </FormControl>
              <Typography variant="body1" className={classes.addOnLabel}>
                CP{' '}
              </Typography>

              <FormControl
                variant="outlined"
                className={classes.tinyDividedField}
              >
                <TextField
                  disabled={!editing}
                  type="number"
                  className={classes.numberInput}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={inkPaws}
                  onChange={e => {
                    setInkPaws(Number(e.target.value));
                  }}
                  variant="outlined"
                />
              </FormControl>
              <Typography variant="body1" className={classes.addOnLabel}>
                IP{' '}
              </Typography>

              <FormControl
                variant="outlined"
                className={classes.tinyDividedField}
              >
                <TextField
                  disabled={!editing}
                  type="number"
                  className={classes.numberInput}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={nosePrints}
                  onChange={e => {
                    setNosePrints(Number(e.target.value));
                  }}
                  variant="outlined"
                />
              </FormControl>
              <Typography variant="body1" className={classes.addOnLabel}>
                NP{' '}
              </Typography>
            </div>
          </FormControl>
        </div>
      </div>
      {editing && (
        <Toolbar>
          <Box
            display="flex"
            justifyContent="end"
            width="100%"
            className={classes.toolbarBox}
          >
            <Button onClick={cancel} className={classes.cancelButton}>
              Cancel
            </Button>
            <Button onClick={update} className={classes.saveButton}>
              Save
            </Button>
          </Box>
        </Toolbar>
      )}
      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </div>
  );
};
