import React, { useEffect, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Pet } from '../shared/types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { get, patch } from '../../utils/Api';
import { OwnerModel } from '../../models';
import IconButton from '@material-ui/core/IconButton';
import extractUUID from '../../utils/ExtractUUID';
interface Props {
  record?: Pet;
  reload?: boolean;
}

const styles = makeStyles({
  petsContainer: {
    margin: '30px',
  },
  headers: {
    textAlign: 'left' as const,
    color: '#000',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '17px',
    paddingBottom: '15px',
  },
  items: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '14px',
    fontWeight: 400,
    padding: '7px 0',
    color: '#626262',
    overflowWrap: 'break-word',
  },
  link: {
    cursor: 'pointer',
    color: '#257A8B',
    textDecoration: 'underline',
    float: 'right',
  },
  table: {
    width: '100%',
    marginBottom: '45px',
    tableLayout: 'fixed',
  },
  petName: {
    cursor: 'pointer',
    color: '#257A8B',
    textDecoration: 'underline',
  },
});

export const PetOwnersList: React.FC<Props> = ({ record, reload }) => {
  const history = useHistory();
  const [owners, setOwners] = useState<OwnerModel[]>([]);
  const [primary, setPrimary] = useState('');
  const [secondary, setSecondary] = useState('');
  const [pet, setPet] = useState<Pet | undefined>(record);
  const classes = styles();

  useEffect(() => {
    const fetchPetData = async () => {
      const response = await get({
        endpoint: `pets/${extractUUID(record?.id ?? '')}`,
      });
      const petData = await response.json();
      setPrimary(petData.primaryOwner?.id ?? '');
      setSecondary(petData.secondaryOwner?.id ?? '');
    };

    fetchPetData();
  }, [record]);

  useEffect(() => {
    get({ endpoint: `owners?pets.id=${record?.originId}` }).then(
      async response => {
        const jsonOwners = await response.json();
        setOwners(jsonOwners['hydra:member']);
      },
    );
  }, [record, reload, pet]);

  const makePrimary = async (ownerId: string) => {
    const petBody = JSON.stringify({
      primaryOwner: `owners/${ownerId}`,
      secondaryOwner:
        secondary === '' || secondary === ownerId
          ? null
          : `owners/${secondary}`,
    });
    const rec = await patch({
      endpoint: `pets/${extractUUID(record?.id ?? '')}`,
      body: petBody,
    });
    const jsonRec = await rec.json();
    setPet(jsonRec);
    setPrimary(ownerId);
    setSecondary(secondary === ownerId ? '' : secondary);
  };

  const makeSecondary = async (ownerId: string) => {
    const petBody = JSON.stringify({
      primaryOwner:
        primary === '' || primary === ownerId ? null : `owners/${primary}`,
      secondaryOwner: `owners/${ownerId}`,
    });
    const rec = await patch({
      endpoint: `pets/${extractUUID(record?.id ?? '')}`,
      body: petBody,
    });

    const jsonRec = await rec.json();
    setPet(jsonRec);
    setPrimary(primary === ownerId ? '' : primary);
    setSecondary(ownerId);
  };

  return (
    <div className={classes.petsContainer}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.headers}>Client Name</th>
            <th className={classes.headers}>Primary</th>
            {owners.length > 1 && (
              <th className={classes.headers}>Secondary</th>
            )}
            <th className={classes.headers}>Telephone</th>
            <th className={classes.headers}>Email</th>
          </tr>
        </thead>
        <tbody>
          {owners.map(owner => {
            return (
              <tr key={`owner-${owner.id}`}>
                <td
                  className={classes.items}
                  onClick={() =>
                    history.push(`/owners/%2Fowners%2F${owner.id}/show`)
                  }
                >
                  <label className={classes.petName}>{owner?.firstname}</label>
                </td>
                <td className={classes.items}>
                  {primary && owner.id === primary ? (
                    <CheckIcon style={{ paddingLeft: '12px' }} />
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() => makePrimary(owner.id)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </td>
                {owners.length > 1 && (
                  <td className={classes.items}>
                    {secondary && owner.id === secondary ? (
                      <CheckIcon style={{ paddingLeft: '12px' }} />
                    ) : (
                      <IconButton
                        aria-label="delete"
                        onClick={() => makeSecondary(owner.id)}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </td>
                )}
                <td className={classes.items}>{owner.telephone}</td>
                <td className={classes.items}>{owner.email}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
