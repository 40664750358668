import React, { FC } from 'react';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  BooleanField,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const SpeciesShow: FC<ShowProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" addLabel={true} />
        <BooleanField source="deleted" addLabel={true} label="Disabled" />
      </SimpleShowLayout>
    </Show>
  </RolePageProvider>
);
