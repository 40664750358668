import React, { FC } from 'react';
import {
  Edit,
  TextInput,
  EditProps,
  SimpleForm,
  required,
  BooleanInput,
  regex,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const StatesEdit: FC<EditProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Edit {...props} resource="states">
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            required(),
            regex(/^(?!\s)/, 'Code should not be or start with a space.'),
          ]}
          source="code"
        />
        <TextInput
          validate={[
            required(),
            regex(/^(?!\s)/, 'Name should not be or start with a space.'),
          ]}
          source="name"
        />
        <BooleanInput source="deleted" label="Disabled" />
      </SimpleForm>
    </Edit>
  </RolePageProvider>
);
