import React, { FC } from 'react';
import {
  Show,
  TextField,
  ShowProps,
  SimpleShowLayout,
  FunctionField,
  BooleanField,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
import { rolesMap } from '../../utils/Roles';

export const UserShow: FC<ShowProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" addLabel={true} />
        <TextField source="email" addLabel={true} />
        <TextField source="telephone" label="Phone" addLabel={true} />
        <FunctionField
          sortBy="role"
          label="Role"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          render={record => {
            if (undefined === record) {
              return '';
            }
            const foundRole = rolesMap.find(role => role.id === record.role);
            return foundRole ? `${foundRole.name}` : `${record.role}`;
          }}
        />
        <BooleanField source="deleted" label="Disabled" />
      </SimpleShowLayout>
    </Show>
  </RolePageProvider>
);
