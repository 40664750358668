import React, { FC } from 'react';
import {
  Edit,
  TextInput,
  EditProps,
  SimpleForm,
  BooleanInput,
  regex,
  email,
  SelectInput,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
import { formatStringToPhoneNumber } from '../../utils/StringUtils';

export const VeterinarianEdit: FC<EditProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Edit {...props} resource="veterinarians">
      <SimpleForm redirect={props.basePath}>
        <TextInput
          validate={[
            regex(
              /^(?!\s)/,
              'Practice Name should not be or start with a space.',
            ),
          ]}
          source="practiceName"
        />
        <TextInput
          validate={[
            regex(
              /^(?:\D*\d){10}\D*$/,
              'Please enter the full phone number with area code',
            ),
          ]}
          source="practiceContactNumber"
          parse={(v: string) => formatStringToPhoneNumber(v)}
        />
        <TextInput
          validate={[
            regex(
              /^(?:\D*\d){10}\D*$/,
              'Please enter the full fax number with area code',
            ),
          ]}
          source="practiceFaxNumber"
          parse={(v: string) => formatStringToPhoneNumber(v)}
        />
        <TextInput
          validate={[
            email(),
            regex(/^(?!\s)/, 'Email should not be or start with a space.'),
          ]}
          source="email"
          label="Contact Email Address"
        />
        <SelectInput
          source="preferredContactMethod"
          label="Preferred Method of Contact"
          choices={[
            { id: 0, name: 'Phone Number' },
            { id: 1, name: 'Fax Number' },
            { id: 2, name: 'Email' },
          ]}
        />
        <TextInput
          validate={[
            regex(
              /^(?!\s)/,
              'Street Line should not be or start with a space.',
            ),
          ]}
          source="streetLine1"
        />
        <TextInput source="streetLine2" />
        <TextInput
          validate={[
            regex(/^(?!\s)/, 'City should not be or start with a space.'),
          ]}
          source="city"
        />
        <TextInput
          validate={[
            regex(/^(?!\s)/, 'State should not be or start with a space.'),
          ]}
          source="state"
        />
        <TextInput
          validate={[
            regex(/^(?!\s)/, 'Zip should not be or start with a space.'),
          ]}
          source="zip"
        />
        <TextInput
          validate={[
            regex(
              /^(?!\s)/,
              'Contact Name should not be or start with a space.',
            ),
          ]}
          source="contactFirstName"
        />
        <TextInput
          validate={[
            regex(
              /^(?!\s)/,
              'Contact Last name should not be or start with a space.',
            ),
          ]}
          source="contactLastName"
        />
        <BooleanInput source="cac" />
        <BooleanInput source="deleted" label="Disabled" />
      </SimpleForm>
    </Edit>
  </RolePageProvider>
);
