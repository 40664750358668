import React, { FC, useEffect, useState } from 'react';
import { Edit, Button } from 'react-admin';
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { GridShowLayout, RaGrid } from 'ra-compact-ui';
import { RolePageProvider } from '../shared/RolePageProvider';
import { City, Client, OwnerEditProps, State } from '../shared/types';
import { Box, Toolbar } from '@material-ui/core';
import { get } from '../../utils/Api';
import { Notification } from '../shared/Notification';
import { makeStyles } from '@material-ui/core/styles';
import { updateOwner } from '../../utils/OwnerUtils';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  validateEmail,
  formatPhoneNumber,
  removeNonNumeric,
} from '../../utils/StringUtils';
import { sortByDeleted } from '../../utils/DataConverters';

const formStyles = makeStyles({
  formInput: {
    width: '90%',
  },
  grid: {
    justifyItems: 'center',
  },
  formControl: {
    width: '90%',
    marginBottom: '30px',
  },
  gridLayout: {
    // paddingBottom: '70px',
  },
  stateCitySubgrid: {
    width: '90%',
    padding: '0 10% 0 0 !important',
  },
  state: {
    minWidth: '100%',
  },
  city: {
    paddingLeft: '10px',
    minWidth: '100%',
  },
  saveButton: {
    margin: '0px 10px',
    padding: '0.75em 1.5em',
    fontWeight: 500,
    fontSize: '14px',
  },
  cancelButton: {
    margin: '0px 10px',
    padding: '0.75em 2em 0.75em 1.5em',
    fontWeight: 500,
    fontSize: '14px',
  },
  toolbarBox: {
    borderTop: '1px solid',
    paddingTop: '10px',
    marginBottom: '20px',
  },
  label: {},
});

export const OwnerEdit: FC<OwnerEditProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}>
    <Edit {...props} resource="owners" hasShow={false} undoable={false}>
      <OwnerEditForm {...props} />
    </Edit>
  </RolePageProvider>
);

export const OwnerEditForm: FC<OwnerEditProps> = props => {
  const [states, setStates] = useState<State[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [location, setLocation] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [stateSearchText, setStateSearchText] = useState<string>('');
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [citySearchText, setCitySearchText] = useState<string>('');
  const [reload, setReload] = useState(true);
  const classes = formStyles();

  useEffect(() => {
    const getStates = () => {
      get({
        endpoint: `states?searchTerm=${stateSearchText}&itemsPerPage=100`,
      }).then(async response => {
        const statesJson = await response.json();
        setStates(statesJson['hydra:member']);
      });
    };

    getStates();
  }, [stateSearchText]);

  const sortedStates = sortByDeleted(states);

  useEffect(() => {
    const getCities = () => {
      get({
        endpoint: `cities?state.id=${selectedState?.id}&name=${citySearchText}&itemsPerPage=2000`,
      }).then(async response => {
        const citiesJson = await response.json();
        setCities(citiesJson['hydra:member']);
      });
    };
    if (selectedState !== null) {
      getCities();
    }
  }, [citySearchText, selectedState]);

  const sortedCities = sortByDeleted(cities);

  const cancel = () => {
    const updatedOwner: Client = JSON.parse(JSON.stringify(props.record));
    setFirstname(updatedOwner.firstname);
    setLastname(updatedOwner.lastname);
    setPhoneNumber(updatedOwner.telephone ?? '');
    setSecondaryPhoneNumber(updatedOwner.alternateTelephone ?? '');
    setEmail(updatedOwner.email);
    setAddressLine1(updatedOwner.addressLine1 ?? '');
    setLocation(updatedOwner.location ?? '');
    const localState = states.find(
      _state => _state.code === props.record?.state,
    );
    const localCity = cities.find(_city => _city.name === props.record?.city);
    setSelectedState(localState ?? null);
    setSelectedCity(localCity ?? null);
    setPostalCode(updatedOwner.postalCode ?? '');
    props.disableEditing();
    setReload(true);
  };

  // @todo Revisit and update logic (email validation should not depend on last name validation)
  const invalidData = () => {
    const phoneNumberLength = removeNonNumeric(phoneNumber).length;
    const secondaryPhoneNumberLength = removeNonNumeric(secondaryPhoneNumber)
      .length;
    if (
      (phoneNumberLength > 0 && phoneNumberLength < 10) ||
      (secondaryPhoneNumberLength > 0 && secondaryPhoneNumberLength < 10)
    ) {
      return true;
    }

    if (lastname.trim() !== '') {
      if (email.trim() !== '') {
        return !validateEmail(email.trim());
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const update = async () => {
    const updatedOwner: Client = JSON.parse(JSON.stringify(props.record));
    updatedOwner.firstname = firstname;
    updatedOwner.lastname = lastname;
    updatedOwner.telephone = phoneNumber;
    updatedOwner.alternateTelephone = secondaryPhoneNumber;
    updatedOwner.email = email;
    updatedOwner.addressLine1 = addressLine1;
    updatedOwner.location = location;
    updatedOwner.state = selectedState ? selectedState.code : '';
    updatedOwner.city = selectedCity ? selectedCity.name : '';
    updatedOwner.postalCode = postalCode;
    const resOwner = await updateOwner(updatedOwner);
    if (resOwner.ok) {
      setReload(false);
      setSuccess(true);
      setSnackbarMessage('Client Information Updated.');
      setOpenSnackbar(true);
      props.disableEditing();
    } else {
      setSuccess(false);
      setSnackbarMessage('Error while saving.');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (props.record && reload) {
      setFirstname(props.record.firstname);
      setLastname(props.record.lastname);
      setPhoneNumber(props.record.telephone ?? '');
      setSecondaryPhoneNumber(props.record.alternateTelephone ?? '');
      setEmail(props.record.email);
      setAddressLine1(props.record.addressLine1 ?? '');
      setLocation(props.record.location ?? '');

      setPostalCode(props.record.postalCode ?? '');
    }
  }, [props, reload]);

  useEffect(() => {
    if (states !== undefined && states.length > 0 && reload) {
      const localState = states.find(
        _state => _state.code === props.record?.state,
      );
      setSelectedState(localState ?? null);
    }
  }, [states, props, reload]);

  useEffect(() => {
    if (cities !== undefined && cities.length > 0 && reload) {
      const localCity = cities.find(_city => _city.name === props.record?.city);
      setSelectedCity(localCity ?? null);
    }
  }, [cities, props, reload]);

  useEffect(() => {
    if (selectedState === null) {
      setSelectedCity(null);
    }
  }, [selectedState]);

  return (
    <>
      <GridShowLayout {...props} className={classes.gridLayout}>
        <RaGrid spacing={3} container className={classes.grid}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>First Name</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.isEditing}
              value={firstname}
              onChange={e => setFirstname(e.target.value as string)}
              variant="outlined"
              className={classes.formInput}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>Last Name*</InputLabel>
            <TextField
              error={lastname === '' || lastname === null}
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.isEditing}
              value={lastname}
              onChange={e => setLastname(e.target.value as string)}
              variant="outlined"
              className={classes.formInput}
              helperText={
                lastname === '' || lastname === null ? 'Field Required' : ''
              }
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>Phone Number</InputLabel>
            <TextField
              type="phone"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.isEditing}
              value={phoneNumber}
              onChange={e =>
                formatPhoneNumber(e.target.value as string, setPhoneNumber)
              }
              variant="outlined"
              className={classes.formInput}
              error={removeNonNumeric(phoneNumber).length < 10}
              helperText={
                phoneNumber !== '' && removeNonNumeric(phoneNumber).length < 10
                  ? 'Please enter the full phone number with area code'
                  : ''
              }
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>
              Secondary Phone Number
            </InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.isEditing}
              value={secondaryPhoneNumber}
              onChange={e =>
                formatPhoneNumber(
                  e.target.value as string,
                  setSecondaryPhoneNumber,
                )
              }
              variant="outlined"
              className={classes.formInput}
              error={removeNonNumeric(secondaryPhoneNumber).length < 10}
              helperText={
                secondaryPhoneNumber !== '' &&
                removeNonNumeric(secondaryPhoneNumber).length < 10
                  ? 'Please enter the full phone number with area code'
                  : ''
              }
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>Email</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.isEditing}
              value={email}
              onChange={e => setEmail(e.target.value as string)}
              variant="outlined"
              className={classes.formInput}
              error={email !== '' && !validateEmail(email.trim())}
              helperText={
                email !== '' && !validateEmail(email.trim())
                  ? 'Email should be valid'
                  : ''
              }
            />
          </FormControl>
          <></>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>Address</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.isEditing}
              value={addressLine1}
              onChange={e => setAddressLine1(e.target.value as string)}
              variant="outlined"
              className={classes.formInput}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>Location</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.isEditing}
              value={location}
              onChange={e => setLocation(e.target.value as string)}
              variant="outlined"
              className={classes.formInput}
            />
          </FormControl>
          <RaGrid container item className={classes.stateCitySubgrid}>
            <RaGrid item xs={6} className={classes.state}>
              <InputLabel className={classes.label}>State</InputLabel>
              <Autocomplete
                id="client-select-state-modal"
                options={sortedStates}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={evt =>
                      setStateSearchText(evt.target.value as string)
                    }
                  />
                )}
                getOptionLabel={option => option.name.toString()}
                getOptionDisabled={option => option.deleted}
                onChange={(evt, value) => setSelectedState(value)}
                value={selectedState}
                disabled={!props.isEditing}
              />
            </RaGrid>
            <RaGrid item xs={6} className={classes.city}>
              <InputLabel className={classes.label}>City</InputLabel>
              <Autocomplete
                disabled={selectedState === null || !props.isEditing}
                id="client-select-city-modal"
                options={sortedCities}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={evt =>
                      setCitySearchText(evt.target.value as string)
                    }
                  />
                )}
                getOptionLabel={option => option.name.toString()}
                getOptionDisabled={option => option.deleted}
                onChange={(evt, value) => setSelectedCity(value)}
                value={selectedCity}
              />
            </RaGrid>
          </RaGrid>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>Postal Code</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.isEditing}
              value={postalCode}
              onChange={e => setPostalCode(e.target.value as string)}
              variant="outlined"
              className={classes.formInput}
            />
          </FormControl>
        </RaGrid>
      </GridShowLayout>
      {props.isEditing && (
        <Toolbar>
          <Box
            display="flex"
            justifyContent="end"
            width="100%"
            className={classes.toolbarBox}
          >
            <Button
              onClick={cancel}
              label="Cancel"
              className={classes.cancelButton}
              classes={classes}
            />
            <Button
              onClick={update}
              disabled={invalidData()}
              label="Save"
              className={classes.saveButton}
              classes={classes}
            />
          </Box>
        </Toolbar>
      )}
      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </>
  );
};
