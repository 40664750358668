import React, { FC, useEffect, useState } from 'react';
import { Edit } from 'react-admin';
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { GridShowLayout, RaGrid } from 'ra-compact-ui';
import { RolePageProvider } from '../shared/RolePageProvider';
import { makeStyles } from '@material-ui/core/styles';
import {
  Breed,
  Diagnosis,
  Pet,
  PetEditProps,
  Species,
  Veterinarian,
} from '../shared/types';
import { Box, Toolbar, Button } from '@material-ui/core';
import { Notification } from '../shared/Notification';
import { updatePet } from '../../utils/PetUtils';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get } from '../../utils/Api';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from 'react-datepicker';
import extractUUID from '../../utils/ExtractUUID';
import { referralMap } from '../../utils/Referrals';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { sortByDeleted } from '../../utils/DataConverters';

const formStyles = makeStyles({
  formInput: {
    width: '90%',
  },
  formControl: {
    width: '90%',
    marginBottom: '30px',
  },
  grid: {
    justifyItems: 'center',
  },
  gridLayout: {
    // paddingBottom: '70px',
  },
  state: {
    minWidth: '0',
  },
  stateCitySubgrid: {
    width: '90%',
    gridTemplateColumns: '100px auto',
    padding: '0 10% 0 0 !important',
  },
  city: {
    minWidth: '100%',
  },
  saveButton: {
    margin: '0px 10px',
    padding: '0.75em 1.5em',
    fontWeight: 500,
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#373F41',
    '&:hover': {
      backgroundColor: 'rgba(55, 63, 65, 0.6)',
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(55, 63, 65, 0.6)',
      color: 'white',
    },
  },
  cancelButton: {
    margin: '0px 10px',
    padding: '0.75em 2em 0.75em 1.5em',
    fontWeight: 500,
    fontSize: '14px',
  },
  toolbarBox: {
    borderTop: '1px solid',
    paddingTop: '10px',
    marginBottom: '20px',
  },
  label: {
    transform: 'none',
  },
  referralBorder: {
    borderBottom: '1px solid #000',
    borderRadius: '0px',
  },
});

export const PetEdit: FC<PetEditProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_STAFF']}>
    <Edit {...props} resource="pets" hasShow={false} undoable={false}>
      <PetEditForm {...props} />
    </Edit>
  </RolePageProvider>
);

export const PetEditForm: FC<PetEditProps> = props => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [species, setSpecies] = useState<Species[]>([]);
  const [speciesSearchText, setSpeciesSearchText] = useState<string>('');
  const [selectedSpecies, setSelectedSpecies] = useState<Species | null>(null);
  const [breeds, setBreeds] = useState<Breed[]>([]);
  const [selectedBreed, setSelectedBreed] = useState<Breed | null>(null);
  const [breedSearchText, setBreedSearchText] = useState<string>('');
  const [referral, setReferral] = useState<string>('');
  const [age, setAge] = useState<number>(0);
  const [weight, setWeight] = useState<number>(0);
  const [gender, setGender] = useState<string>('');
  const [euthanasiaQuote, setEuthanasiaQuote] = useState<string>('');
  const [aftercareQuote, setAftercareQuote] = useState<string>('');
  const [selectedVeterinarians, setSelectedVeterinarians] = useState<
    Veterinarian[]
  >(() => []);
  const [veterinarians, setVeterinarians] = useState<Veterinarian[]>([]);
  const [veterinarianSearchText, setVeterinarianSearchText] = useState<string>(
    '',
  );
  const [diagnosis, setDiagnosis] = useState<Diagnosis | null>(null);
  const [diagnosisArr, setDiagnosisArr] = useState<Diagnosis[]>([]);
  const [diagnosisSearchText, setDiagnosisSearchText] = useState<string>('');
  const [dateOfDeath, setDateOfDeath] = useState<Date | null>(null);
  const classes = formStyles();

  const cancel = () => {
    const updatedPet: Pet = JSON.parse(JSON.stringify(props.record));
    setSelectedSpecies(updatedPet.breed?.species ?? null);
    setSelectedBreed(updatedPet.breed ?? null);
    setAge(updatedPet.age);
    setWeight(updatedPet.weight);
    setGender(updatedPet.gender);
    setName(updatedPet.name);
    setAftercareQuote(updatedPet.aftercareQuote ?? '');
    setEuthanasiaQuote(updatedPet.euthanasiaQuote ?? '');
    setLastname(updatedPet.lastname);
    setReferral(updatedPet.referral);
    setDiagnosis(updatedPet.diagnosis ?? null);
    if (updatedPet.dateOfDeath !== undefined) {
      const selectedDate = new Date(updatedPet.dateOfDeath);
      setDateOfDeath(selectedDate);
    } else {
      setDateOfDeath(null);
    }

    if (updatedPet.veterinarians && updatedPet.veterinarians.length > 0) {
      setSelectedVeterinarians(updatedPet.veterinarians);
    } else {
      setSelectedVeterinarians([]);
    }
    props.disableEditing();
  };

  const update = async () => {
    const updatedPet: Pet = JSON.parse(JSON.stringify(props.record));
    updatedPet.name = name;
    updatedPet.aftercareQuote = aftercareQuote;
    updatedPet.euthanasiaQuote = euthanasiaQuote;
    updatedPet.lastname = lastname;
    updatedPet.gender = gender;
    updatedPet.weight = weight ?? 0.0;
    updatedPet.age = age ?? 0;
    updatedPet.breed = selectedBreed ?? undefined;
    updatedPet.species = selectedSpecies ?? undefined;
    updatedPet.referral = referral;
    updatedPet.veterinarians = selectedVeterinarians
      ? selectedVeterinarians
      : [];
    updatedPet.diagnosis = diagnosis ?? undefined;
    if (dateOfDeath) {
      const userTimezoneOffset = dateOfDeath.getTimezoneOffset() * 60000;
      updatedPet.dateOfDeath = new Date(
        dateOfDeath.getTime() + userTimezoneOffset,
      );
    } else {
      updatedPet.dateOfDeath = undefined;
    }
    updatedPet.id = extractUUID(updatedPet.id);
    const resPet = await updatePet(updatedPet);
    if (resPet.ok) {
      setSuccess(true);
      setSnackbarMessage('Pet Information Updated.');
      setOpenSnackbar(true);
      props.disableEditing();
    } else {
      setSuccess(false);
      setSnackbarMessage('Error while saving.');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    const getSpecies = () => {
      get({
        endpoint: `species?deleted=false&name=${speciesSearchText}&order%5Bname%5D=asc`,
      }).then(async response => {
        const speciesJson = await response.json();
        setSpecies(speciesJson['hydra:member']);
      });
    };

    getSpecies();
  }, [speciesSearchText]);

  const sortedSpecies = sortByDeleted(species);

  useEffect(() => {
    const getBreeds = () => {
      get({
        endpoint: `breeds?deleted=false&species.id=${selectedSpecies?.id}&name=${breedSearchText}&order%5Bname%5D=asc`,
      }).then(async response => {
        const breedsJson = await response.json();
        setBreeds(breedsJson['hydra:member']);
      });
    };
    if (selectedSpecies !== null) {
      getBreeds();
    }
  }, [breedSearchText, selectedSpecies]);

  const sortedBreeds = sortByDeleted(breeds);

  useEffect(() => {
    const getFilteredVeterinarians = () => {
      get({
        endpoint: `veterinarians?deleted=false&practiceName=${veterinarianSearchText}&order%5BpracticeName%5D=asc`,
      }).then(async response => {
        const veterinariansJson = await response.json();
        setVeterinarians(veterinariansJson['hydra:member']);
      });
    };

    getFilteredVeterinarians();
  }, [veterinarianSearchText]);

  const sortedVeterinarians = sortByDeleted(veterinarians);

  useEffect(() => {
    const getFilteredDiagnosis = () => {
      get({
        endpoint: `diagnoses?deleted=false&description=${diagnosisSearchText}&order%5Bdescription%5D=asc`,
      }).then(async response => {
        const diagnosisJson = await response.json();
        setDiagnosisArr(diagnosisJson['hydra:member']);
      });
    };

    getFilteredDiagnosis();
  }, [diagnosisSearchText]);

  const sortedDiagnoses = sortByDeleted(diagnosisArr);

  useEffect(() => {
    if (props.record) {
      setSelectedSpecies(
        props.record.species
          ? props.record.species
          : props.record.breed?.species ?? null,
      );
      setSelectedBreed(props.record.breed ?? null);
      setAge(props.record.age);
      setWeight(props.record.weight);
      setGender(props.record.gender);
      setName(props.record.name);
      setAftercareQuote(props.record.aftercareQuote ?? '');
      setEuthanasiaQuote(props.record.euthanasiaQuote ?? '');
      setLastname(props.record.lastname);
      setReferral(props.record.referral);
      setDiagnosis(props.record.diagnosis ?? null);
      if (props.record.dateOfDeath !== undefined) {
        const selectedDate = new Date(props.record.dateOfDeath);
        setDateOfDeath(selectedDate);
      }

      if (props.record.veterinarians && props.record.veterinarians.length > 0) {
        setSelectedVeterinarians(props.record.veterinarians);
      }
    }
  }, [props.record]);

  return (
    <>
      <GridShowLayout {...props} className={classes.gridLayout}>
        <RaGrid spacing={3} container className={classes.grid}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>Pet Name</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.editing}
              value={name}
              onChange={e => setName(e.target.value as string)}
              variant="outlined"
              className={classes.formInput}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>
              Preferred Last Name*
            </InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.editing}
              value={lastname}
              onChange={e => setLastname(e.target.value as string)}
              variant="outlined"
              className={classes.formInput}
              error={lastname.trim() === ''}
              helperText={lastname.trim() === '' ? 'Field is required' : ''}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Species</InputLabel>
            <Autocomplete
              disabled={!props.editing}
              id="client-select-species-modal"
              options={sortedSpecies}
              renderInput={params => (
                <TextField
                  {...params}
                  onChange={evt =>
                    setSpeciesSearchText(evt.target.value as string)
                  }
                />
              )}
              getOptionLabel={option => option.name.toString()}
              getOptionDisabled={option => option.deleted}
              onChange={(evt, value) => setSelectedSpecies(value)}
              value={selectedSpecies}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Breed</InputLabel>
            <Autocomplete
              disabled={!props.editing}
              id="client-select-breed-modal"
              options={sortedBreeds}
              renderInput={params => (
                <TextField
                  {...params}
                  onChange={evt =>
                    setBreedSearchText(evt.target.value as string)
                  }
                />
              )}
              getOptionLabel={option => option.name.toString()}
              getOptionDisabled={option => option.deleted}
              onChange={(evt, value) => setSelectedBreed(value)}
              value={selectedBreed}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>Age</InputLabel>
            <TextField
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.editing}
              value={age}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              //@ts-ignore
              onChange={e => setAge(e.target.value)}
              variant="outlined"
              className={classes.formInput}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>Gender</InputLabel>
            <Autocomplete
              disabled={!props.editing}
              id="client-select-breed-modal"
              options={['female', 'male']}
              renderInput={params => (
                <TextField
                  {...params}
                  onChange={evt =>
                    setBreedSearchText(evt.target.value as string)
                  }
                />
              )}
              getOptionLabel={option =>
                option.charAt(0).toUpperCase() + option.slice(1)
              }
              onChange={(evt, value) => setGender(value as string)}
              value={gender}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label}>Weight</InputLabel>
            <TextField
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.editing}
              value={weight}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              //@ts-ignore
              onChange={e => setWeight(e.target.value)}
              variant="outlined"
              className={classes.formInput}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel style={{ transform: 'inherit' }}>Referral</InputLabel>
            <Select
              id="referral"
              disabled={!props.editing}
              placeholder={'Select Referral'}
              value={referral}
              onChange={e => setReferral(e.target.value as string)}
              className={classes.referralBorder}
            >
              {referralMap.map(_ref => {
                return (
                  <MenuItem key={_ref.id} value={_ref.id}>
                    {_ref.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Veterinarian</InputLabel>
            <Autocomplete
              disabled={!props.editing}
              multiple={true}
              id="vet-select-modal"
              options={sortedVeterinarians}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Search Veterinarians"
                  onChange={evt =>
                    setVeterinarianSearchText(evt.target.value as string)
                  }
                />
              )}
              getOptionLabel={option => option.practiceName.toString()}
              getOptionDisabled={option => option.deleted}
              onChange={(evt, value) => setSelectedVeterinarians(value)}
              value={selectedVeterinarians}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Diagnosis</InputLabel>
            <Autocomplete
              disabled={!props.editing}
              id="diagnosis-select-modal"
              options={sortedDiagnoses}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Select Diagnosis"
                  onChange={evt =>
                    setDiagnosisSearchText(evt.target.value as string)
                  }
                />
              )}
              getOptionLabel={option => option.description.toString()}
              getOptionDisabled={option => option.deleted}
              onChange={(evt, value) => setDiagnosis(value)}
              value={diagnosis}
            />
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Euthanasia Quote</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.editing}
              value={euthanasiaQuote}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              //@ts-ignore
              onChange={e => setEuthanasiaQuote(e.target.value)}
              variant="outlined"
              className={classes.formInput}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Aftercare Quote</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!props.editing}
              value={aftercareQuote}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              //@ts-ignore
              onChange={e => setAftercareQuote(e.target.value)}
              variant="outlined"
              className={classes.formInput}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel style={{ marginBottom: '10px' }}>DoD</InputLabel>
            <DatePicker
              portalId="root-portal"
              disabled={!props.editing}
              selected={dateOfDeath}
              onChange={date => setDateOfDeath(date)}
              showTimeSelect
              dateFormat="M/d/Y h:mm aa"
            />
          </FormControl>
        </RaGrid>
      </GridShowLayout>
      {props.editing && (
        <Toolbar>
          <Box
            display="flex"
            justifyContent="end"
            width="100%"
            className={classes.toolbarBox}
          >
            <Button
              onClick={cancel}
              className={classes.cancelButton}
              classes={classes}
            >
              Cancel
            </Button>
            <Button
              onClick={update}
              disabled={lastname.trim() === ''}
              className={classes.saveButton}
              classes={classes}
            >
              Save
            </Button>
          </Box>
        </Toolbar>
      )}

      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </>
  );
};
