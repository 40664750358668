export const sortByDeleted = <T extends { deleted: boolean }>(
  items: T[] | undefined,
): T[] => {
  if (!items) {
    return [];
  }
  return items.sort((a, b) => {
    if (a.deleted === b.deleted) {
      return 0;
    }
    return a.deleted ? 1 : -1;
  });
};
