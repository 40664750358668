import React, { FC } from 'react';
import {
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  BooleanField,
  SelectField,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';

export const VeterinarianShow: FC<ShowProps> = props => (
  <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="practiceName" />
        <TextField source="practiceContactNumber" />
        <TextField source="practiceFaxNumber" />
        <TextField source="email" label="Contact Email Address" />
        <SelectField
          source="preferredContactMethod"
          choices={[
            { id: 0, name: 'Phone Number' },
            { id: 1, name: 'Fax Number' },
            { id: 2, name: 'Email' },
          ]}
        />
        <TextField source="streetLine1" />
        <TextField source="streetLine2" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="zip" />
        <TextField source="contactFirstName" />
        <TextField source="contactLastName" />
        <BooleanField source="deleted" label="Disabled" />
        <BooleanField source="cac" />
      </SimpleShowLayout>
    </Show>
  </RolePageProvider>
);
